.register {
  &-modal {
    width: 1000px !important;
    padding: 0;

    .ant-modal-body {
      padding: 24px 0;
      padding-bottom: 0;
    }
  }

  &-title {
    text-align: center;
    color: #303133;
    font-size: 18px;
    margin-bottom: 10px;
  }
  & .ant-input-search{
    margin-left: 20px;
  }
  & .ant-tabs {
    height: 609px;
    font-size: 1rem;
    padding-left:20px;
  }

  &-list {
    padding-bottom: 24px;
    box-shadow: inset 0px 0 0 0 #000,
    inset 0px -10px 10px -10px #efefef;
    height: 500px;
    overflow: scroll;

    & dl {
      font-size: 1rem;
      & p {
        margin-right: 20px;
      }
      & dt{
        margin: 10px 0;
      }
      & dd {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 20px 0px rgba(183, 183, 183, 0.53);
    border-radius: 0px 0px 4px 4px;

    & button {
      width: 86px;
      height: 32px;
      border: none;
      border-radius: 3px;
      background: #37B4A0;
      color: #fff;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}