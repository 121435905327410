.meet-chat {
  position: absolute;
  z-index: 99;
  left: 0;
  bottom: 100px;
  width: 645px;
  height: 70%;
  background-color: rgba(#fff, 0.98);
  overflow: hidden;
  border-radius: 10px;
  // box-shadow: #f7f7f7 0px 1px 1px 1px;
  flex-direction: column;
  display: flex;
  &__header {
    background-color: #37b4a0;
    height: 65px;
    line-height: 65px;
    color: #fff;
    font-size: 22px;
    padding-left: 30px;
    position: relative;
    &-close {
      font-size: 0;
      display: block;
      position: absolute;
      right: 0px;
      top: 0;
      width: 65px;
      height: 65px;
      text-align: center;
      line-height: 65px;
      img {
        vertical-align: middle;
        margin-top: -2px;;
      }
    }
  }
  &__bar {
    height: 40px;
    line-height: 40px;
    // position: absolute;
    width: 100%;
    // bottom: 150px;
    font-size: 18px;
    font-weight: bold;
    padding-left: 20px;
    // height: 0;
    &-desc {
      color: #f56833;
      font-weight: normal;
      font-weight: 18px;
    }
  }
  &__input {
    height: 190px;
    // flex: 1;
    // position: absolute;
    width: 100%;
    bottom: 0;
    border-top: 1px solid #cccccc;
    &-ta {
      width: 100%;
      border: none;;
      height: 100px !important;
      background: none;
      padding: 0px 0 0 20px;
      font-size: 18px;
      outline: none;
      border: none;
      -webkit-appearance: none;
      resize: none;
      &:focus, &:hover {
        outline: none;
      border: none;
      -webkit-appearance: none;
      resize: none;
      box-shadow: none;
      }
    }
  }
  button{
    float: right;
    margin-right: 20px;
    border-color:#37b4a0;
    background-color: #37b4a0;
    &:focus, &:hover{
      border-color:#179480;
      background-color: #179480;
    }
    &:active{
      border-color:#077460;
      background-color: #077460;
    }
  }
  &__msgs {
    width: 100%;
    // height: 100%;
    // position: absolute;
    overflow: hidden;
    flex: 1;
    
    
    &-list {
      padding: 10px 20px;
      // position: absolute;
      flex: 1;
      height: 100%;
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    &-item {
      margin-bottom: 10px;
      margin-right: 140px;
      overflow: hidden;
      &.me {
        text-align: right;
        margin-right: 0;
        margin-left: 140px;
        .meet-chat__msgs-msg {
          float: right;
        }
      }
    }
    &-nick {
      font-size: 14px;
      color: #999;
    }
    &-msg {
      font-size: 16px;
      text-align: left;
      float: left;
    }
  }
  &__select {
    vertical-align: middle;
    margin-top: -2px;
    background: none;
    &.ant-select-open {
      .ant-select-selector {
        border: none!important;
        color: #37b4a0;
        font-size: 18px;
      }
    }
    .ant-select-selector, .ant-select-selector:hover {
      border: none!important;
      color: #37b4a0;
      font-size: 16px;
      box-shadow: none!important;
      -webkit-box-shadow: none!important;
    }
    
  }
}