
#edit-do {
  width: 88%;
  padding: 16px;
  position: relative;
}

#edit-do #title {
  font-size: 1.5rem;
  color: #333;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

#edit-do #title span.doing {
  //display: inline-block;
  //width: 70px;
  //height: 26px;
  //font-size: 14px;
  //line-height: 26px;
  border: 1px solid #4185ED;
  //border-radius: 4px;
  background:rgba(222,233,251,1);
  color: #4185ED;
  //text-align: center;
}
#edit-do #title span {
  display: inline-block;
  width: 70px;
  height: 26px;
  font-size: 14px;
  line-height: 26px;
  border: 1px solid #F0A900;
  border-radius: 4px;
  background: rgba(254, 248, 235, 1);
  color: #F0A900;
  text-align: center;
}
#edit-do #edit-content {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.35);
  display: flex;
  justify-content: center;
  // height: 88%;
  padding-top: 80px;
  margin-bottom: 20px;
}

#edit-do #edit-content ul {
  width: 80%;
}

#edit-do .meet-date{
  width: 82%;
}

#edit-do .meet-time{
  width: 82%;
}

#edit-do .meet-time .ant-picker{
  width: 28% !important;
}
#edit-do .meet-time span, #edit-do .meet-date span{
  font-size: 1rem;
  color: #777;
}

#edit-do li {
  display: flex;
}

#edit-do li div:first-child {
  width: 220px;
  margin-right: 20px;
}

#edit-do li div:last-child {
  width: 80%;
}
#edit-do li div:last-child .ant-picker{
  width:60%;
}
#edit-do li div:last-child .ant-picker .ant-picker-input{
  width:100%;
}
#edit-do li div:last-child .ant-picker svg{
  color:#ccc;
}
#edit-do table {
  // width: 100%;
  width:600px;
}

#edit-do table tr {
  height: 46px;
  font-size: 15px;
}

#edit-do tbody tr {
  border-bottom: 1px solid #DDD;
}

#edit-do table tr td:first-child {
  padding-left: 10px;
  width:300px
}
#edit-do table tr td:last-child {
  width:170px
}
#edit-do tbody tr td:first-child a{
  color:#666;
}
#edit-do tbody tr td:last-child a{
  color:#FF3F3F;
}
#edit-do table thead {
  background: #F3F3F3;
  font-weight: bold;
}

#edit-do #edit-left p:first-child {
  font-size: 1rem;
  color: #555;
  font-weight: bold;
  margin-bottom: 10px;
}

#edit-do #edit-left p:nth-child(2) {
  color: #999;
}

#edit-do #edit-left, #edit-right {
  margin-bottom: 16px;
}
#edit-do #edit-left{
  min-width: 200px;
}
#edit-do  #edit-right{
  min-width: 890px;
}
#edit-do #edit-right label {
  display: inline-block;
  margin-bottom: 14px;
}

#edit-do #edit-right #meeting-name {
  margin-bottom: 30px;
}



#edit-right #time {
  white-space: nowrap;
  width: 80%;
}

#edit-right #password {
  width: 80%;
  margin-top: 10px;
}

#edit-right #meeting-name {
  width: 80%;
}
#edit-right #password .ant-input-password{
  width:60%;
}

//#edit-right #password input {
//  width: 60%;
//  height: 30px;
//  border: 1px solid rgba(204, 204, 204, 1);
//  border-radius: 2px;
//  outline: none;
//  padding-left: 8px;
//}

#edit-right #meeting-name input {
  width: 60%;
  height: 30px;
  border: 1px solid rgba(204, 204, 204, 1);
  border-radius: 2px;
  outline: none;
  padding-left: 8px;
}

#edit-right #password input::-webkit-input-placeholder {
  color: rgba(204, 204, 204, 1);
}

#edit-right #meeting-name, #time, #password span {
  font-size: 1rem;
  color: #777;
}

#edit-right #setting, #upload-file {
  font-size: 1rem;
  color: #000;
}

#edit-right #setting > input {
  margin-right: 5px;
}

#edit-right #setting, #upload-file > input {
  margin-right: 5px;
}

#edit-right #up-button {
  width: 90px;
  height: 32px;
  background: rgba(66, 134, 237, 1);
  border-radius: 4px;
  border:none;
  color:#EEE;
  margin-bottom: 20px;
}
#edit-bottom{
  width:88%;
  position: fixed;
  right:0;
  bottom:0;
  height:60px;
  background: #fff;
  box-shadow:0px -4px 10px 0px rgba(153,153,153,0.3);
  display: flex;
  align-items: center;
}
#edit-bottom div{
  width:100%;
  min-width:400px;
  text-align: right;
}
#edit-bottom button{
  width: 90px;
  height: 34px;
  border-radius: 4px;
  border:none;
  margin-right: 10px;
}
#edit-bottom button:nth-child(1){
  border:1px solid rgba(204,204,204,1);
  background: #fff;
  color:#333;
}
#edit-bottom button#cancel-meeting{
  border:1px solid rgba(204,204,204,1);
  background: #fff;
  color:#FF3F3F;
}
#edit-bottom button#enter-meeting{
  background:rgba(55,180,160,1);
}
#edit-bottom button#enter-meeting a{
  color:#fff;
}
#edit-bottom button:last-child{
  background:rgba(55,180,160,1);
  color:#fff;
}
#delete-file{
    color:#FF3F3F;
}
#meeting-name span:nth-child(2){
  color:#333;
}
#time span:nth-child(2){
  color:#333;
}
button {
  cursor: pointer;
}