
.manage{
  &-modal {
    width: 1000px!important;
    padding: 0;
    .ant-modal-body  {
      padding: 24px 0;
      padding-bottom: 0;
    }
  }
  &-search {
    padding: 0 24px;
    margin-bottom: 10px;
  }
  &-title {
    text-align: center;
    color: #303133;
    font-size: 20px;
  }
  &-class {
    line-height: 60px;
    color: #666666;
    font-size: 20px;
    padding: 0 24px;
    &::before {
      content: '';
      display: inline-block;
      width: 7px;
      height: 7px;
      border-radius: 7px;;
      background-color: #4286ed;
      margin-right: 10px;
      vertical-align: middle;
      margin-top: -2px;
    }
  }
  &-list {
    // padding: 0 24px;
    padding-bottom: 24px;
    box-shadow:inset 0px 0 0 0 #000,
    inset 0px -10px 10px -10px #efefef; 
    height: 500px;
    overflow: scroll;
  }
  &-member {
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    color: #333333;
    // margin: 10px 0;
    position: relative;
    padding: 10px 24px;
    box-sizing: content-box;
    &:hover {
      background-color: #f7fafd;
      // .manage-member__right {
      //   display: none;
      //   &.hover {
      //     display: block;
      //   }
      // }
    }
    &.withcontrol {
      &:hover {
        .manage-member__right {
          display: none;
          &.hover {
            display: block;
          }
        }
      }
    }
    &__icon {
      width: 60px;
      height: 60px;
      border-radius: 60px;
      vertical-align: middle;
      margin-top: -2px;
      margin-right: 10px;
      
    }
    &__right {

      position: absolute;
      right: 24px;
      height: 40px;
      top: 10px;
      font-size: 0;
      // line-height: 60px;
      &.hover {
        display: none;
      }
      &-btn {
        width: 40px;
        height: 40px;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        line-height: 40px;
        margin: 0 5px;
        margin-top: -2px;
        font-size: 0;
        img {
          vertical-align: middle;
          margin-top: -2px;
        }
        &[disabled]{
          img{
            filter: grayscale(100%) !important;
          }
        }
      }
      &-tbtn {
        cursor: pointer;
        display: inline-block;
        margin-right: 15px;
        font-size: 16px;
        color: #fff;;
        background-color: #37b4a0;
        padding: 0 20px;
        border-radius: 3px;
        height: 40px;
        line-height: 40px;
        vertical-align: middle;
        margin-top: -2px;
      }
      &-kick {
        cursor: pointer;
        width: 38px;
        height: 38px;
      }
    }
  }
  &-footer {
    height: 80px;
    line-height: 80px;
    text-align: right;
    &__btn {
      vertical-align: middle;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      display: inline-block;
      border: 1px solid #cccccc;
      border-radius: 5px;
      margin-right: 20px;
      color: #37b4a0;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
