#join-box{
  height:100%;
}
#join-box #title {
    height: 11%;
    font-family: PingFang SC;
    font-size: 2rem;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 97px;
    p {
        margin: 0;
    }
}
#join-form {
    box-shadow: 0px 0px 20px 0px rgba(183, 183, 183, 0.53);
    height: 81%;
    min-width: 600px;
    display: flex;
    justify-content: center;
}
#join-form form{
    width:30%;
    min-width: 446px;
    color:#656565;
    margin-top: 5%;
    position: relative;
    & .ant-form-item:first-child{
        & .ant-form-item-control{
            margin-left: 13px;
        }
    }
    .ant-form-item{
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .ant-checkbox-checked .ant-checkbox-inner{
        background-color: #37B39F;
        border-color: #37B39F;
    }
}
#join-form form input{
    width:350px;
    height:30px;
    border:1px solid #CBCBCB;
    border-radius:2px;
    outline: none;
    padding-left: 10px;
}

#business-card{
    border: 1px dotted #CBCBCB;
    width:436px;
    height:100px;
    margin: 4px 0 5px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:#333;
    border-radius: 2px;
}
#business-card ul{
    margin:0;
}
#business-card li{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
#business-card li img{
    width:18px;
    height:18px;
    margin:0 8px 0 8px;
}
#business-card #authentication{
    border-left: 1px dotted #CBCBCB;
    width:80px;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:#4185ED;
}
#business-card #authentication img{
    width:26px;
    height:30px;
}
#join-form form .footer-buttons{
    padding-top: 30px;
    text-align: center;
}
#join-form form button.cancel{
    background: transparent;
    color: #37B39F;
    border: 1px solid #37B39F;
}
#join-form form button{
    width:150px;
    margin: 30px;
    height:40px;
    color:#fff;
    background: #37B39F;
    border:none;
    border-radius: 4px;
}
#join-form form button:disabled{
    color:#fff;
    background: #999;
}

#join-form form button a{
    color:#fff;
}
#join-form form .enter_room{
    border: 1px solid #e3dfdf;
    background: #fff;
    box-shadow: 0px 10px 20px 0px rgba(121, 121, 121, 0.35);
    border-radius: 6px;
    padding:8px 0;
    text-align: center;
    position: absolute;
    top:360px;
    left:50%;
    transform: translateX(-50%);
    & ul{
        margin-bottom: 0;
    }
    & li{
        white-space: nowrap;
        height:28px;
        line-height: 28px;
        padding:0 20px;
        &:hover{
            background:rgba(245,247,250,1);
        }
    }
}