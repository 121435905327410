.loading {
  top: 40%;
  transform: translateY( -50%);
  width: 100%;
  position: absolute;
  &-progress {
    width: 570px;
    height: 27px;
    background: linear-gradient(#d3d3d3, #dee1e2);
    border-radius: 27px;
    margin: 0 auto;
    margin-top: 50px;
    position: relative;
    &__inner {
      position: absolute;
      border-radius: 27px;
      width: 100%;
      height: 27px;
      background: url(../../../images/meet/progress.png);
      background-size: 570px 27px;
      transition: width 1s linear;
    }
    
  }
  &-status {
    margin-top: 15px;
    font-size: 16px;
  }
}