#status-box {
  height: 100%;
}

#container {
  //height: 92%;
}
.edit-container{
  height: 92%;
}
#meeting-status {
  width: 12%;
  min-width: 201px;
  border-right: 1px solid #DDDDDD;
  //height: 99%;
}

#meeting-status a {
  text-decoration: none;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  font-size: 18px;
  font-weight: 500;
}

#container {
  display: flex;
}

#list-box {
  width: 88%;
  min-width: 860px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.35);
  border-radius: 8px;
  margin: 20px;
  padding: 20px;
}

#meeting-status .active {
  background: rgba(240, 242, 242, 1);
  color: #37B3AD;
}

#list-title {
  font-weight: bold;
  font-size: 18px;
  color: rgba(51, 51, 51, 1);
  letter-spacing: 1px;
  display: flex;
  margin: 12px 0;
  & p{
    margin:0;
  }
}

#list-title span {
  display: inline-block;
  width: 4px;
  height: 18px;
  background: rgba(239, 169, 0, 1);
  margin-right: 10px;
  margin-top: 6px;
}

#list-box table {
  border-collapse: collapse;
  width: 100%;
  color: #656565;
}

#list-box table thead {
  background: #F3F3F3;
  height: 52px;
  font-weight: bold;
}

#list-box thead tr {
  height: 56px;
}
.spin{
  width:100%;
  //margin-left: auto;
  //margin-right: auto;
}
#list-box tbody tr {
  border-bottom: 1px solid #DDD;
  height: 74px;
  font-size: 15px;
}
#list-box tbody tr td {
  vertical-align: top;
  padding-top: 10px;
}

#list-box table tr td:last-child {
  width: 110px;
}

#list-box tbody tr td:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

#list-box tbody tr td:last-child > a {
  text-decoration: none;
  color: #37B39F;
}


#list-box tbody tr td:last-child img {
  margin-left: 14px;
  &:hover{
    cursor: pointer;
  }
}
#list-box table tr td:first-child {
  padding-left: 30px;
}

#list-box thead tr td:last-child {
  text-align: center;
}

#list-box tbody tr #edit {
  display: none;
  width: 140px;
  //height: 90px;
  background: #fff;
  border: 1px solid rgba(227, 223, 223, 1);
  box-shadow: 0px 10px 20px 0px rgba(121, 121, 121, 0.35);
  border-radius: 6px;
  padding: 8px 0;
  //text-align: center;
  //line-height: 40px;
  position: absolute;
  top: 48px;
  right: 0;
  z-index: 1;
  &.show {
    display: block;
  }
}

#edit #triangle {
  width: 0;
  height: 0;
  border-width: 0 8px 8px;
  border-style: solid;
  border-color: transparent transparent rgba(227, 223, 223, 1) transparent;
  position: relative;
  top: -111px;
  left: 98px;
}

#triangle span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 0 7px 7px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  top: 1px;
  left: -7px;
}

#list-box tbody tr td:nth-child(2) p {
  margin-bottom: 8px;
}

.page {
  text-align: right;
  margin-top: 30px;
}


//#page a {
//  text-decoration: none;
//  color: #999999;
//  margin: 5px;
//}
//
//#page img {
//  width: 14px;
//  height: 14px;
//}

.show {
  display: block;
}

#check-update-list{
  text-align: center;
  padding: 30px;
  h4{
    font-weight: bold;
  }
  button{
    display: inline-block;
    padding: 2px 10px;
    border: 1px solid #37B39F;
    color: #37B39F;
    border-radius: 5px;
    background: transparent;
  }
}

.version-check-modal{
  .ant-modal-body{
    padding-top: 50px;
    h4{
      font-weight: bold;
      margin-bottom: 20px;
    }
    span.version{
      color: #37B39F;
      margin: 0 4px;
    }
    button{
      background-color: #37B39F;
    }
  }
}