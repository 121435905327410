*{margin:0;padding:0}
html,body,#root,#box{
    width:100%;
    height:100%;
}
#video,#canvas{
    position: absolute;
    width:800px;
    top:80px;left:220px;
}
#box{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
#box #face_header{
    width:100%;
    height:12%;
    min-height: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FAFBFC;
}
#face_header #header-text{
    text-align: center;
}
#face_header #header-text p:first-child{
    font-size: 2rem;
    font-family:PingFang SC;
    color:rgba(51,51,51,1);
    letter-spacing: 2px;
    margin:0 0 10px;
}
#face_header #header-login{
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
}
#face_header #header-login a{
    text-decoration: none;
    color:#37B39F;
}
#face-form{
    width:100%;
    height: 88%;
    box-shadow:0px 0px 20px 0px rgba(203, 203, 203, 0.35);
    display: flex;
    justify-content: center;
    position: relative;
}
#form-right{
    width:300px;
    height:513px;
    position:absolute;
    top: 100px;
    left: 1070px;
}






//#form-left{
//    width:60%;
//    height:100%;
//}
//#form-right{
//    width:30%;
//    height:100%;
//}
//#face-form #form-left{
//    width:60%;
//    min-width: 772px;
//    text-align: right;
//    padding:50px 30px 0 0;
//}
//#face-form  #form-right{
//    height: 370px;
//    position: relative;
//    min-width: 233px;
//}
#form-right #check{
   color:#666;
   text-align: left;
}
#button-group{
    width: 253px;
    height: 40px;
    position: absolute;
    left:0;bottom:0;
}
#capture-group{
    display: flex;
    &.show{
        display: block;
    }
    &.hide{
        display: none;
    }
}
#video{
    display: block;
    &.show{
        display: block;
    }
    &.hide{
        display: none;
    }
}
#upload-group,#loading{
    //display: flex;
    display: none;
    &.show{
        display: flex;
    }
}
#canvas{
    display: none;
    &.show{
        display: flex;
    }
}
#button-group button{
    flex: 1;
    height:40px;
    background:rgba(55,180,160,1);
    box-shadow:0px 0px 15px 0px rgba(55,180,160,0.45);
    border-radius:6px;
    border:none;
    color:#fff;
    margin-right: 20px;
}
.show{
    display:block;
}
.hide{
    display:none;
}