* {
    margin: 0;
    padding: 0
}

html, body {
    height: 100%;
    font-family:PingFang SC;
}
ul{
  list-style: none;
}
#header {
    height: 8%;
    box-shadow: 0px 0px 20px 0px rgba(183, 183, 183, 0.53);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    min-height: 71px;
    min-width: 1100px;
}
#header-logo{
    min-width: 400px;
    height:100%;
    display: flex;
    align-items: center;
}
#header-logo img {
    height:100%;
}

#header-logo span {
    color: #666;
    font-size: 1rem;
    font-weight: bold;
    font-family: PingFang SC;
    letter-spacing: 1px;
    margin-left: 10px;
}

#header-meeting {
    position: absolute;
    margin-left: 50%;
    padding-left: 100px;
    transform: translateX(-50%);
    width: 650px;
}

#header-meeting button {
    width: 160px;
    height: 48px;
    //line-height: 48px;
    background: linear-gradient(0deg, rgba(55, 180, 160, 1), rgba(55, 179, 180, 1));
    border-radius: 4px;
    border: none;
    margin: 0 10px;
    font-size: 16px;
    //position: relative;
}
#header-meeting button a{
    color: #ffffff;
}
#header-meeting img {
    width: 24px;
    margin-right: 8px;
    //position: absolute;
    //top: 50%;
    //left: 20px;
    //transform: translateY(-50%);
}

#header-meeting button:nth-child(2) img {
    width: 28px;
}

#header-meeting span {
    margin-left: 16px;
}

#header-user {
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-right: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(102, 102, 102, 1);
    letter-spacing: 1px;
    min-width: 80px;
    text-decoration: none;
    position:relative;
}

#header-user #user {
    width: 32px;
    margin-right: 5px;
}

#header-user #drop-down {
    width: 10px;
    margin-left: 5px;
}
#me{
    box-sizing: border-box;
    width:160px;
    // height:90px;
    background: #fff;
    border:1px solid rgba(227,223,223,1);
    box-shadow:0px 10px 20px 0px rgba(121, 121, 121, 0.35);
    border-radius: 6px;
    text-align: center;
    //line-height: 45px;
    position: absolute;
    top:62px;
    right:10px;
    z-index: 1;
    padding: 8px 0;
    ul {
        margin-bottom: 0;
    }
}
#me ul li{
    height:36px;
    padding-left: 24px;
    text-align: left;
    display: flex;
    align-items: center;
    font-weight: bold;
}
#me ul li img{
    margin-right: 10px;
    width:18px;
    height:18px;
}
#me ul li a{
    color:#666;
    font-size: 15px;
}
#me ul li:hover{
    background:rgba(245,247,250,1);
}
#me ul li:hover a{
    color:#37B3AD;
}
#me ul li:hover img{
    filter: drop-shadow(0px 0px 0px #00FFFF);
}
#me #triangle{
    width:0;height:0;
    border-width: 0 8px 8px;
    border-style: solid;
    border-color: transparent transparent rgba(227,223,223,1) transparent;
    position: relative;
    top:-52px;
    left:98px;
}
#me #triangle span{
    position:absolute;
    display: block;
    width:0;height:0;
    border-width: 0 7px 7px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
    top:1px;
    left: -7px;
}