
.set{
  &-modal {
    width: 900px!important;
    padding: 0;
    .ant-modal-body  {
      padding: 24px 0;
      padding-bottom: 0;
    }
  }
  
  &-title {
    text-align: center;
    color: #303133;
    font-size: 20px;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #cccccc;
  }
  &-con {
    height: 600px;
    display: flex;
    &__left {
      width: 250px;
      height: 100%;
      border-right: 1px solid #ccc;
      &-item {
        text-align: left;
        font-size: 20px;
        height: 60px;
        line-height: 60px;
        background-color: #37b4a0;
        color: #fff;
      }
    }
    &__right {
      flex: 1;
      padding: 30px;
      &-item {
        display: block;
        line-height: 40px;
        font-size: 16px;
        color: #333333;
      }
    }
  }
  &-icon {
    &__set {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url(../../../images/meet/set.png);
      background-size: 20px 20px;
      vertical-align: middle;
      margin-top: -2px;
      margin-right: 10px;
      margin-left: 50px;
      // position: absolute;

    }
  }
}
