#create-box{
    height: 100%;
}
#create-box #title {
    height: 11%;
    min-width: 950px;
    font-family: PingFang SC;
    font-size: 2rem;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        margin: 0;
    }
}

#create-form {
    box-shadow: 0px 0px 20px 0px rgba(183, 183, 183, 0.53);
    // height: 81%;
    min-width:950px ;
    min-height: 730px;
    display: flex;
    justify-content: center;
    font-size: 1rem;
}
#create-form a{
    color:#37B4A0;
    font-size: 1rem;
    text-decoration: underline;
}
#create-form form{
    margin-top: 60px;
    color:#666;
    letter-spacing: 1px;
    position: relative;
    width:30%;
}
#create-form form select{
    height:28px;
    outline: none;
}
//#create-form form input:focus{
//    outline: none;
//    border:1px solid #999;
//}
#create-form label{
    display: block;
    white-space: nowrap;
}
#create-form label .explain-box{
    white-space: normal;
}
#create-form label.required::after{
    content: '*';
    color: red;
    padding-left:10px;
}
#create-form #member{
    margin-top: 14px;
    color:#000;
    font-size: 1rem;
}
#create-form #branch-venue{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
}
#create-form #branch-venue img{
    width:15px;
    margin-left: 7px;
}
#create-form #branch-venue a{
    font-size: 15px;
    margin-left: 7px;
}
#create-form .ant-input-password{
    width: 62%;
    margin-left: 10px;
    display: none;
    &.show{
        display: inline-flex;
    }
}
//#write-password{
//    height:28px;
//    border:1px solid #CCCCCC;
//    margin-left: 8px;
//    padding-left: 8px;
//    width:63%;
//    display: none;
//    &.show{
//        display: inline-block;
//    }
//}
//#write-password::-webkit-input-placeholder {
//    color: #CBCBCB;
//}
//#write-password label{
//    margin-bottom: 8px;
//}
#allow-join{
    line-height: 30px;
}
.open-password,#allow-upload,#allow-join,#watermark{
    color:#000;
    margin-top: 8px;
}
#create-form .open-password{
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 32px;
}
#create-form form .footer-buttons{
    margin-top: 30px;
    margin-bottom: 20px;
}
#create-form form .footer-buttons button{
    width:150px;
    height:40px;
    border-radius:6px;
    margin: 20px;
    border-width: 1px;
    &.cancel{
        background-color: transparent;
        border: 1px solid #37B39F;
        color:#37B39F;
    }
}
#create-form form #submit{
    border:none;
    background:rgba(55,179,159,1);
    color:#fff;
    // position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
}
#create-form form button:disabled{
    color:#fff !important;
    background: #999 !important;
}
#create-form form #submit a{
    color:#fff;
}

#member-window{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    display: none;
    &.show{
        display: block;
    }
}

#member-list {
    width: 50%;
    height: 50%;
    margin-top: 15%;
    margin-left: 25%;
    background: #fff;
    flex-direction: column;
    display: flex;
}

#overflow-list{
    flex: 1;
    width: 100%;
    overflow-y: scroll;
    font-size: 15px;
}

#member-top{
    height:50px;
    line-height: 50px;
    position: relative;
    text-align: center;
    color:#000;
    font-family:PingFang SC;
}
#member-top #exit{
    width:25px;
    height: 25px;
    position: absolute;
    right: 4%;
    top:50%;
    transform: translateY(-50%);
    cursor: pointer;
}
#member-top #exit img{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
#member-search{
    margin-left: 20px;
}
#member-search form{
    width:30%;
    height:28px;
    line-height: 28px;
    position: relative;
    border:1px solid #CBCBCB;
    border-radius: 4px;
    overflow: hidden;
}
#member-search form input{
    width:76%;
    height:28px;
    border:none;
    position:absolute;
    top:0;
    padding-left: 8px;
}
#member-search form input::-webkit-input-placeholder {
    color: #CBCBCB;
}
#member-search form input:focus{
    outline: none;
}
#member-search form button{
    width:20%;
    height:28px;
    position: absolute;
    top:0;
    right:0;
    border:none;
    border-left:1px solid #CBCBCB;
    outline: none;
}
#overflow-list .member-list{
    padding-left: 20px;
    padding-right: 20px;
}
#overflow-list .member-list>dt{
    color:rgba(101,101,101,1);
}
#overflow-list .member-list>dt{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height:40px;
    line-height: 40px;
}
#overflow-list .member-list>dd{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height:40px;
    line-height: 40px;
}
#overflow-list a{
    text-decoration: none;
    color:#333;
}
#overflow-list hr{
    margin-left: -20px;
}
#buttons{
    height: 50px;
    line-height: 50px;
    text-align: right;
    padding-right: 20px;
    box-shadow:0px 0px 20px 0px rgba(183,183,183,0.53);
    border-radius:0px 0px 4px 4px;
}
#buttons button{
    width:66px;
    height:28px;
    line-height: 28px;
    border:none;
    background:#fff ;
    color:#999999;
    border:1px solid rgba(203,203,203,1);
    border-radius:4px ;
    cursor: pointer;
}
#buttons button:last-child{
    background:#37B39F ;
    color:#fff;
    margin-left: 10px;
}
.default-checkbox{
    display: inline-block;
    width: 13px;
    height: 13px;
    position: relative;
    margin-right: 5px;
}
.default-checkbox input[type="checkbox"]{
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
}
.default-checkbox input[type="checkbox"]:after{
    display: block;
    width: 13px;
    height: 13px;
    content: "";
    border-radius: 2px;
    border: solid 1px #d8dadc;
}
.default-checkbox input[type="checkbox"]:checked:after{
    content: "";
    background-image: url("../../../images/meeting/create/checked.png");
    background-size: 13px;
    border: none;
}
.default-checkbox input[type="checkbox"]:disabled:after{
    content:"";
    background-image: url("../../../images/meeting/create/un_checked.png");
    background-size: 13px;
    border: none;
}
.spot{
    color:#4185ED;
    margin-right: 6px;
}
#member-person a{
    display: flex;
    align-items: center;
}
#member-person a img{
    margin-right: 8px;
}
.show{
    display:block;
}
.border{
    border-color: #bbb;
    color:black;
    width: 70%;
}
.DatePicker{
    width:70%;
}
#room_num .ant-input-number{
    width:68%;
}
#form-setting,#branch-venue{
    margin-top: 5px;
}
.explain-box{
    display: none;
    font-size: 12px;
    width:370px;
    height:210px;
    border:1px solid #333;
    border-radius: 5px;
    padding:8px;
    background: #fff;
    position: absolute;
    top:-64px;
    left: 158px;
    z-index: 1;
    &.show{
        display:block
    }
}
.left{
 position: absolute;
    top: 68px;
    left: -16px;
}
.left:before,.left:after{
 position: absolute;
 content: '';
 border-top: 8px transparent dashed;
 border-left: 8px transparent dashed;
 border-bottom: 8px transparent dashed;
 border-right: 8px #fff solid;
}
.left:before{
 border-right: 8px #333 solid;
}
.left:after{
 left: 1px; /*覆盖并错开1px*/
 border-right: 8px #fff solid;
}

.file-list-box {
    margin-top: 10px;
}