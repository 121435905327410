//#edit{
//    box-sizing: border-box;
//    width:160px;
//    height:90px;
//    background: #fff;
//    border:1px solid rgba(227,223,223,1);
//    box-shadow:0px 10px 20px 0px rgba(121, 121, 121, 0.35);
//    border-radius: 6px;
//    text-align: center;
//    //line-height: 45px;
//    position: absolute;
//    top:74px;
//    right:10px;
//    padding: 8px 0;
//}
#edit ul{
    height:80px;
    font-weight: bold;
    color:#333;
}

#edit ul li{
    height:36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#edit ul li a{
    color:#666;
    font-size: 15px;
}
#edit ul li:hover{
    background:rgba(245,247,250,1);
}
#edit ul li:hover a{
    color:#37B3AD;
}
.ant-modal-footer{
    text-align: center;
}
// .ant-btn-primary{
//     background: #37B4A0;
//     //border-color: #37B4A0;
//     border:none;
// }
// .ant-btn-primary:hover{
//     background: #37B4A0;
//     border-color: #333;
// }
.ant-btn:hover{
    border-color: #999;
}
.ant-modal-footer button:first-child:hover{
    color:rgba(0, 0, 0, 0.65);
}
.doing{
    color:#4286ED;
}
#enter-meeting{
    position: relative;
    color:#333;
}
#enter-room{
    //padding:10px;
    //width:140px;
    background: #fff;
    position: absolute;
    top:40px;
    right:0;
    z-index: 1;
    border: 1px solid #e3dfdf;
    background: #fff;
    box-shadow: 0px 10px 20px 0px rgba(121, 121, 121, 0.35);
    border-radius: 6px;
    padding:8px 0;
    text-align: center;
}
//#enter-room #triangle {
//  width: 0;
//  height: 0;
//  border-width: 0 8px 8px;
//  border-style: solid;
//  border-color: transparent transparent rgba(227, 223, 223, 1) transparent;
//  position: relative;
//    top:0;
//    left:0
//}
//
//#triangle span {
//  position: absolute;
//  display: block;
//  width: 0;
//  height: 0;
//  border-width: 0 7px 7px;
//  border-style: solid;
//  border-color: transparent transparent #fff transparent;
//  top: 1px;
//  left: -7px;
//}
#enter-room ul{
    margin-bottom: 0;
}
#enter-room li{
    white-space: nowrap;
    height:28px;
    line-height: 28px;
    padding:0 20px;
}
#enter-room li:hover{
    background:rgba(245,247,250,1);
}
#enter-room li:hover a{
    color:#37B3AD;
}
#list-box tbody tr .do-edit{
  height:90px
}
#list-box tbody tr .did-del{
  height:54px
}
#list-box tr .week{
    font-weight: 600;
    font-size: 1rem;
}

.personal-file-box #file-box{
    position: fixed !important;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}