/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

button.ant-btn-primary{
    border-color:#37b4a0;
    background-color: #37b4a0;
}
button.ant-btn-primary:focus, button.ant-btn-primary:hover{
    border-color:#179480;
    background-color: #179480;
}
button.ant-btn-primary:active{
    border-color:#077460;
    background-color: #077460;
}