#file-box{
  width:100%;
  height:100%;
  position: absolute;
  left:0;
  top:0;
  z-index: 10;
  background: transparent;
  background: #fff;
}
#file-header {
    height: 8%;
    min-width: 874px;
    min-height: 71px;
    box-shadow: 0px 0px 20px 0px rgba(183, 183, 183, 0.53);
    // position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 50px;
}
#file-header>button{
  width:80px;
  height:34px;
  background: #37B4A0;
  color:#fff;
  border:none;
  border-radius:4px;
  cursor: pointer;
}
#file-header #file-header-logo{
  width: 222px;
  height:100%;
}
#file-header img {
  height:100%;
}
#file-header #file-meeting{
  // position:absolute;
  // top:50%;
  // left:50%;
  // transform: translate(-50%,-50%);
  text-align: center;
  & p{
    margin:0;
  }
}
#file-header #meeting-code{
  font-weight: 500;
  color:#666666
}
#file-header #meeting-theme{
  font-size: 1rem;
  color:#666;
  font-weight: 800;
}
#file-content{
  width:98%;
  min-width: 720px;
  height:88%;
  margin-top: 1%;
  box-shadow:0px 0px 20px 0px rgba(204, 204, 204, 0.35);
  border: 1px solid #eee;
  border-radius:8px;
  margin-left: auto;
  margin-right: auto;
  padding:20px;
  position: relative;
  background: #fff;
}
#file-content-title{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
#file-content-title span {
  display: inline-block;
  width: 4px;
  height: 18px;
  background: rgba(239, 169, 0, 1);
  margin-right: 10px;
}
.file-opt{
  &>div{
    display: inline;
    button{
      cursor: pointer;
      margin-right: 1em;
    }
  }
  .file-iptc{
    line-height: 30px;
    height: 30px;
    vertical-align: top;
    margin-top: -5px;
    width: 100%;
    cursor: pointer;
    input{
      cursor: pointer;
    }
  }
}
#file-content-title p{
  margin-bottom: 0;
  font-size: 1rem;
  color:#333;
  font-weight: bold;
}
#file-content-upload{
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
#file-content-upload img{
  width:14px;
}
#file-content-upload div{
  margin:0;
}
#file-content-upload div>span{
  font-size: 15px;
  margin-right: 14px;
}
#file-content-upload div>a{
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  //position: relative;
}
#file-content-upload div div span{
  margin-right: 5px;
}
#file-content-upload button{
  width:80px;
  height:34px;
  background: #37B4A0;
  color:#fff;
  border:none;
  border-radius:4px;
}
.file-table  table {
  width: 100%;
}
.file-table  table tr {
  height: 46px;
  font-size: 15px;
}
.file-table  table tr td:last-child {
  text-align: center;
}
.file-table table thead {
  background: #F3F3F3;
  font-weight: bold;
}
.file-table tbody tr {
  border-bottom: 1px solid #DDD;
}
.file-table table tr td:first-child {
  padding-left: 10px;
}
.file-table tbody tr td:nth-child(1) a{
  color: #666 !important;
  text-decoration: none !important;
  display: flex;
  align-items: center;
}
.file-table tbody tr td:nth-child(1) a img{
  margin-right: 4px;
}
.file-table tbody tr td:last-child a{
  padding-right: 1em;
  &.download{
    color:#37B4A0;
  }
  &.delete{
    color:#FF3F3F;
  }
}
#file-content #no-file{
  color:#656565;
  text-align: center;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}
#file-content #no-file img{
  width:240px;
}
#file-content #no-file p{
  margin:0;
}
.file {
  &-iptc {
    position: relative;
    overflow: hidden;
  }
  &-ipt {
    height:100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
#file-content .manage-upload{
    box-sizing: border-box;
    width:160px;
    height:90px;
    background: #fff;
    border:1px solid rgba(227,223,223,1);
    box-shadow:0px 10px 20px 0px rgba(121, 121, 121, 0.35);
    border-radius: 6px;
    text-align: center;
    //line-height: 45px;
    position: absolute;
    top: 82px;
    left: 52px;
    z-index: 1;
    padding: 8px 0;
}
.manage-upload ul li{
    height:36px;
    padding-left: 24px;
    text-align: left;
    display: flex;
    align-items: center;
    font-weight: bold;
}
.manage-upload ul li a{
    color:#666;
    font-size: 14px;
}
.manage-upload ul li:hover{
    background:rgba(245,247,250,1);
}
.manage-upload ul li:hover a{
    color:#37B3AD;
}
.manage-upload #triangle{
    width:0;height:0;
    border-width: 0 8px 8px;
    border-style: solid;
    border-color: transparent transparent rgba(227,223,223,1) transparent;
    position: relative;
    top:-100px;
    left:98px;
}
.manage-upload #triangle span{
    position:absolute;
    display: block;
    width:0;height:0;
    border-width: 0 7px 7px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
    top:1px;
    left: -7px;
}

// #file-box {
//   width: 80%;
//   height: 80%;
//   position: absolute;
//   left: 10%;
//   top: 10%;
//   z-index: 0;
//   // background: #fff;
// }