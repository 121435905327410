* {
    margin: 0;
    padding: 0
}
:local(body) {
    width: 100%;
    height: 100%;
    background-color: #F3F5FF;
}
:local(#box) {
    width: 100%;
    height: 100%;
    background-color: #F3F5FF;
    overflow: hidden;
    position: absolute;
}
.userInput{
    width:100%;
}
#cloud-review {
    width: 742px;
    height: 90px;
    font-size: 120px;
    font-family: Arial;
    font-weight: bold;
    color: rgba(228, 233, 255, 1);
    line-height: 90px;
    white-space: nowrap;
    transform-origin: 0 90px;
    transform: rotate(90deg);
    position: absolute;
    top: 70px;
    left: 100px;
}

#cloud-review p {
    margin-left: -5px;
}

#cloud {
    position: absolute;
    top: 702px;
    left: 100px;
}
.video{
    position: absolute;
    top: 301px;
    left: 213px;
    width: 70px;
    height: 70px;
}
#voice {
    position: absolute;
    top: 102px;
    left: 250px;
}

#login-box {
    width: 70%;
    min-height: 650px;
    height: 70%;
    min-width: 640px;
    max-width: 1140px;
    box-shadow: 0px 0px 46px 0px rgba(165, 203, 210, 0.35);
    border-radius: 20px;
    box-sizing: content-box;
    position: absolute;
    top: 7%;
    left: 20%;
    display: flex;
}

#login-box form {
    background: rgba(255, 255, 255, 1);
    border-radius: 20px 0px 0px 20px;
    height: 100%;
    padding: 0 80px;
    position: relative;
    .ant-form-item-explain-error {
        padding: 0 12px;
    }
}
@media screen and (max-width:1200px ) {
    #login-image{
        display: none;
    }
    #login-box form {
        border-radius: 20px;
    }
}

#login-form {
    flex: 1;
}

#login-image {
    flex: 1;
    overflow: hidden;
    border-radius: 0 20px 20px 0;
    position: relative;
}
#login-image #login-image-text{
    position: absolute;
    top:32%;
    left:52%;
    transform: translateX(-50%);
}
#login-image-text p:first-child{
    font-size: 4rem;
    font-family:PingFang SC;
    color:rgba(255,255,255,1);
    letter-spacing: 8px;
    white-space:nowrap;
}
#login-image-text p:last-child{
    padding-left:1rem;
    font-size: 2rem;
    font-family:Arial;
    color:rgba(221,221,221,1);
    white-space:nowrap;
}
#login-image img {
    height: 100%;
}

#login-form #title {
    width: 189px;
    height: 31px;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(55, 180, 160, 1);
    line-height: 43px;
    white-space: nowrap;
    padding-top: 20%;
    margin-left: 1px;
    margin-bottom: 72px;
}
#login-form .label-box{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #EAEAEF;
    padding: 10px;
}
#login-form .captcha{
    width:100%;
    position: relative;
}
#login-form .captcha .ant-form-item{
    width:50%;
}
#login-form .captcha #identify-code {
    position: absolute;
    right:0;
}
#login-form .remember{
    padding-bottom: 20px;
    margin-bottom: 10px;
}
#login-form .ant-form-item{
    width:100%;
    margin-bottom: 0;
}
#login-form .ant-form-item-control-input-content {
    & input{
        border:none;
    }
    & .ant-input-password{
        border:none;
    }
}
#login-form .ant-form-item-control-input-content input,.ant-input-password::-webkit-input-placeholder {
    color: #999;
    font-size: 1rem;
}
#login-form form img{
    margin-right: 10px;
}
#login-form form .submit .ant-form-item-control-input-content{
    border:none;
}
#login-form form .remember .ant-form-item-control-input-content{
    border:none;
}
#login-form .ant-form-item-control-input-content button {
    width:100%;
    height:60px;
    background:rgba(55,180,160,1);
    box-shadow:0px 0px 15px 0px rgba(55,180,160,0.45);
    border-radius:6px;
    border:none;
    font-size: 1.5rem;
    color:rgba(255,255,255,1);
    letter-spacing: 0.5rem;
}
.captcha{
    width:55%;
}
#face-login{
    position: absolute;
    right:86px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    font-family:PingFang SC;
    letter-spacing: 1px;
    font-size: 1.1rem;
    font-weight: 400;
}
#face-login a{
    text-decoration: none;
    color:#37B4A0;
    cursor: pointer;
}
#face-login img{
    margin-right: 10px;
    width:20px;
}
